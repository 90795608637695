<template>
  <div>
    <div id="baner" class="banner">
      <!-- <div class="banner"></div> -->
      <!-- <img src="../assets/banner-1.jpeg" alt="" class="banner"> -->
      <swiper
        :slides-per-view="1"
        :space-between="50"
        navigation
        :pagination="{ clickable: true }"
        :autoplay ="{ delay: 4500 }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide>
          <img class="banner-img" src="../assets/banner-1.jpeg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner-2.jpeg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner-3.jpeg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner-4.jpeg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="banner-img" src="../assets/banner-5.jpeg" alt="" />
        </swiper-slide>
      </swiper>
    </div>
    <AboutUs />
    <ServiceItems />
    <MoveHouseFlowAndTidbits />
  </div>
</template>

<script>
import AboutUs from '@/views/AboutUs.vue'
import ServiceItems from '@/views/ServiceItems.vue'
import MoveHouseFlowAndTidbits from '@/views/MoveHouseFlowAndTidbits.vue'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
import '@/assets/scss/navigation.scss'
import '@/assets/scss/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import { useMeta } from 'vue-meta'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    AboutUs,
    ServiceItems,
    MoveHouseFlowAndTidbits
  },
  setup () {
    useMeta({
      title: '五路搬家-每趟2800、傢俱回收、檔案銷毀、拆除、緊急派車｜五路環保科技有限公司',
      og: {
        title: '五路環保科技有限公司 - 搬家好選擇',
        description: '搬家服務、學生優惠、五金拆解回收、機密文件檔案銷毀、傢俱回收、廢棄物清除、裝潢拆除、莊園整理、割草、鋸樹、全台緊急派車、回頭車'
        // image: [
        //   '@/assets/images/banner-1.jpeg'
        // ]
      }
    })
    var onSwiper = swiper => {
      // console.log(swiper)
    }

    var onSlideChange = () => {
      // console.log('slide change')
    }
    return { onSwiper, onSlideChange }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.banner {
  &-img {
    vertical-align: middle;
    width: 100vw;
    height: 95vh;
    object-fit: cover;
    object-position: center center;
    @include iPad() {
      height: calc(1284 / 1562 * 100vw);
      // object-fit: contain;
    }
  }
}
</style>
