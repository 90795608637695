<template>
  <div class="">
    <ul class="customer-service-list">
      <li class="customer-service-list-item">
        <a href="tel:0422020077" class="customer-service-list-item-link customer-service-phone">
        </a>
      </li>
      <li class="customer-service-list-item">
        <a href="https://reurl.cc/KxNavm" class="customer-service-list-item-link customer-service-line">
          <img src="" alt="">
        </a>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CustomerService'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.customer-service-list {
  position: fixed;
  right: calc(242 / 4000 * 100vw);
  bottom: calc(106 / 2250 * 100vh);
  z-index: 999;
  @include iPad() {
    right: calc(143 / 1563 * 100vw);
    bottom: calc(127 / 1563 * 100vw);
  }
  &-item {
    &-link{
      display: inline-block;
      vertical-align: middle;
      width: calc(214 / 4000 * 100vw);
      height: calc(274 / 4000 * 100vw);
    }
  }
}

.customer-service-phone {
  background-image: url(../assets/估價專線.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: calc(34 / 2250 * 100vh);
  @include iPad() {
    width: calc(231 / 1563  * 100vw);
    height: calc(231 / 1563  * 100vw);
    background-image: url(../assets/估價專線-沒有文字.png);
  }
}

.customer-service-line {
  background-image: url(../assets/加我好友.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include iPad() {
    width: calc(231 / 1563  * 100vw);
    height: calc(231 / 1563  * 100vw);
    background-image: url(../assets/line-add-friend-not-desktop.png);
  }
}
</style>
