<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <!-- <template v-slot:description="{ metainfo }">{{ metainfo.description }}</template> -->
    <template v-slot:og(title)="{ og }">
      {{ og.title }}
    </template>
    <template v-slot:og(description)="{ og }">
      {{ og.description }}
    </template>
    <template v-slot:og(image)="{ og }">
      {{ og.image[0] }}
    </template>
  </metainfo>
  <Header />
  <main>
    <router-view/>
  </main>
  <Footer />
  <CustomerService />
</template>

<script>
import { onMounted } from 'vue'
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CustomerService from '@/components/CustomerService.vue'
// import { useMeta, useActiveMeta } from 'vue-meta'
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    CustomerService
  },
  setup () {
    // Add meta info
    // The object passed into useMeta is user configurable
    useMeta({
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      // titleTemplate: '%s',
      // description: 'description',
      og: {
        title: '',
        description: ''
        // image: [
        //   ''
        // ]
      }
    })
    onMounted(() => {
      document.dispatchEvent(new Event('render-event'))
    })
  }
}

</script>

<style lang="scss">

#app {
  font-family: Taipei Sans TC;
  font-style: normal;
  font-weight: 400;
}

// *, *::before, *::after {
//   outline: 1px solid #ff0000;
// }

img {
  max-width: 100%;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
