
// import Vue from 'vue'
// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pagination/',
    name: 'Pagination',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pagination.vue'),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'price-list',
        name: 'PriceList',
        component: () => import(/* webpackChunkName: "about" */ '../views/PriceList.vue'),
        meta: { scrollToTop: true }
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'appraisal-list',
        name: 'AppraisalList',
        component: () => import(/* webpackChunkName: "about" */ '../views/AppraisalList.vue'),
        meta: { scrollToTop: true }
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'common-problem',
        name: 'CommonProblem',
        component: () => import(/* webpackChunkName: "about" */ '../views/CommonProblem.vue'),
        meta: { scrollToTop: true }
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'move-house-tidbits',
        name: 'MoveHouseTidbits',
        component: () => import(/* webpackChunkName: "about" */ '../views/MoveHouseTidbits.vue'),
        meta: { scrollToTop: true }
      },
      {
        path: 'move-house-tidbits-album/:id',
        name: 'MoveHouseTidbitsAlbum',
        component: () => import(/* webpackChunkName: "about" */ '../views/MoveHouseTidbitsAlbum.vue'),
        meta: { scrollToTop: true }
        // children: [
        //   {
        //     path: '',
        //     component: () => import(/* webpackChunkName: "about" */ '../views/MoveHouseTidbitsAlbumPhoto.vue'),
        //     meta: { scrollToTop: true }
        //   }
        // ]
      }
    ]
  }
]

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    if (to.hash === '#about-us') {
      return {
        el: to.hash,
        top: 100,
        left: 0,
        behavior: 'smooth'
      }
    }
    if (to.hash === '#service-items') {
      return {
        el: to.hash,
        top: 75,
        left: 0,
        behavior: 'smooth'
      }
    }
    if (to.hash === '#move-house-tidbits') {
      return {
        el: to.hash,
        top: 100,
        left: 0,
        behavior: 'smooth'
      }
    }
    if (to.hash === '#footer') {
      return {
        el: to.hash,
        top: 0,
        left: 0,
        behavior: 'smooth'
      }
    }
    if (to.hash === '#footer-not-desktop') {
      return {
        el: to.hash,
        top: 0,
        left: 0,
        behavior: 'smooth'
      }
    }
  }
  if (to.matched.some(m => m.meta.scrollToTop)) {
    return { left: 0, top: 0 }
  }
}

// const webHistory = createWebHistory('/' + __dirname)
// const router = createRouter({
//   history: webHistory,
//   scrollBehavior,
//   routes: [
//     { path: '/', component: Home, meta: { scrollToTop: true } },
//     { path: '/foo', component: Foo },
//     { path: '/bar', component: Bar, meta: { scrollToTop: true } },
//   ],
// })

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes
})

export default router
