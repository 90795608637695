<template>
  <div id="service-items" class="service-items">
    <!-- <h2 class="service-items-title"><img src="../assets/服務項目.png" alt=""></h2> -->
    <h2 class="service-items-title"><span>服務項目</span></h2>
    <ul class="service-items-tags">
      <li class="service-items-tags-item">
        <span class="service-items-tags-item-name">辦公室、承包居家、公司、工廠機關等遷移</span>
      </li>
      <li class="service-items-tags-item">
        <span class="service-items-tags-item-name">傢俱回收</span>
        <span class="service-items-tags-item-name">裝潢拆除</span>
        <span class="service-items-tags-item-name">居家清理</span>
        <span class="service-items-tags-item-name">資源回收</span>
        <span class="service-items-tags-item-name">莊園整理</span>
      </li>
      <li class="service-items-tags-item">
        <span class="service-items-tags-item-name">搬家服務</span>
        <span class="service-items-tags-item-name">物流轉運</span>
        <span class="service-items-tags-item-name">公司行號貨物運輸</span>
        <span class="service-items-tags-item-name">廢棄物清除</span>
        <span class="service-items-tags-item-name">廢棄物處裡</span>
      </li>
      <li class="service-items-tags-item">
        <span class="service-items-tags-item-name">專業吊車搬運</span>
        <span class="service-items-tags-item-name">機密文件檔案銷毀</span>
        <span class="service-items-tags-item-name">全台緊急派車</span>
      </li>
    </ul>
    <ul class="service-items-tags-not-desktop">
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">辦公室、承包居家、公司、工廠機關等遷移</span>
      </li>
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">傢俱回收</span>
        <span class="service-items-tags-not-desktop-item-name">裝潢拆除</span>
        <span class="service-items-tags-not-desktop-item-name">居家清理</span>
      </li>
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">資源回收</span>
        <span class="service-items-tags-not-desktop-item-name">莊園整理</span>
        <span class="service-items-tags-not-desktop-item-name">搬家服務</span>
      </li>
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">物流轉運</span>
        <span class="service-items-tags-not-desktop-item-name">公司行號貨物運輸</span>
        <span class="service-items-tags-not-desktop-item-name">廢棄物清除</span>
      </li>
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">廢棄物處理</span>
        <span class="service-items-tags-not-desktop-item-name">專業吊車搬運</span>
        <span class="service-items-tags-not-desktop-item-name">機密文件檔案銷毀</span>
      </li>
      <li class="service-items-tags-not-desktop-item">
        <span class="service-items-tags-not-desktop-item-name">全台緊急派車</span>
      </li>
    </ul>
    <div class="service-items-info"><img src="../assets/service-items-price-and-phone.png" alt=""></div>
    <div class="service-items-sideline"></div>
    <div class="service-items-price">
      <ul class="service-items-price-need-cost">
        <li>
          <img class="service-items-price-need-cost-image" src="../assets/service-items-need-cost.png" alt="">
        </li>
        <li class="service-items-price-need-cost-item">
          <router-link :to="{ name: 'AppraisalList' }">
            <img src="../assets/service-items-appraisal-list.png" alt="">
          </router-link>
        </li>
        <li class="service-items-price-need-cost-item">
          <router-link :to="{ name: 'PriceList' }">
            <img src="../assets/service-items-price-list.png.png" alt="">
          </router-link>
        </li>
        <li class="service-items-price-need-cost-item">
          <router-link :to="{ name: 'CommonProblem' }">
            <img src="../assets/service-items-common-problem.png" alt="">
          </router-link>
        </li>
      </ul>
      <ul class="service-items-price-free">
        <li>
          <img class="service-items-price-free-image" src="../assets/service-items-free.png" alt="">
        </li>
        <li class="service-items-price-free-item">
          <img src="../assets/service-items-appointment-valuation.png" alt="">
        </li>
        <li class="service-items-price-free-item">
          <img src="../assets/service-items-package.png" alt="">
        </li>
        <li class="service-items-price-free-item">
          <img src="../assets/service-items-pick-good-time.png" alt="">
        </li>
      </ul>
    </div>
    <div class="service-items-price-not-desktop">
      <div class="service-items-price-not-desktop-need-cost-title"><img class="service-items-price-not-desktop-need-cost-image" src="../assets/service-items-need-cost-not-desktop.png" alt=""></div>
      <ul class="service-items-price-not-desktop-need-cost">
        <li class="service-items-price-not-desktop-need-cost-item">
          <router-link :to="{ name: 'AppraisalList' }">
            <img src="../assets/service-items-appraisal-list.png" alt="">
          </router-link>
        </li>
        <li class="service-items-price-not-desktop-need-cost-item">
          <router-link :to="{ name: 'PriceList' }">
            <img src="../assets/service-items-price-list.png.png" alt="">
          </router-link>
        </li>
        <li class="service-items-price-not-desktop-need-cost-item">
          <router-link :to="{ name: 'CommonProblem' }">
            <img src="../assets/service-items-common-problem.png" alt="">
          </router-link>
        </li>
      </ul>
      <ul class="service-items-price-not-desktop-free">
        <li class="service-items-price-not-desktop-free-item">
          <img src="../assets/service-items-appointment-valuation.png" alt="">
        </li>
        <li class="service-items-price-not-desktop-free-item">
          <img src="../assets/service-items-package.png" alt="">
        </li>
        <li class="service-items-price-not-desktop-free-item">
          <img src="../assets/service-items-pick-good-time.png" alt="">
        </li>
      </ul>
      <div class="service-items-price-not-desktop-free-title"><img class="service-items-price-not-desktop-free-image" src="../assets/service-items-free.png" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceItems'
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.service-items {
  padding-top: calc(225 / 2250 * 100vh);
  @include iPad() {
    padding-top: calc(120 / 2779 * 100vh);
  }
  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: calc(84 / 2250 * 100vh);
    @include iPad() {
      margin-bottom: calc(57 / 1563 * 100vw);
    }
    span {
      font-size: calc(76 / 4000 * 100vw);
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      letter-spacing: .2rem;
      background-color: rgba(23, 42, 129, .9);
      border-radius: 50rem;
      padding-top: calc(42 / 2250 * 100vh);
      padding-right: calc(84 / 4000 * 100vw);
      padding-bottom: calc(42 / 2250 * 100vh);
      padding-left: calc(96 / 4000 * 100vw);
      // width: calc(720 / 4000 * 100vw);
      // height: calc(213 / 2250 * 100vh);
      @include iPad() {
        // font-size: calc(62 / 1562 * 100vw);
        font-size: 1rem;
        padding: calc(34 / 1563 * 100vw) calc(84 / 1563 * 100vw);
        margin-bottom: calc(57 / 2279 * 100vh);
        margin-bottom: 0;
      }
    }
  }
  &-tags {
    margin-bottom: calc(122 / 2250 * 100vh);
    @include iPad() {
      display: none;
    }
    &-item {
      display: flex;
      justify-content: center;
      &:not(:last-child) {
        margin-bottom: calc(65 / 2250 * 100vh);
      }
      &-name {
        font-size: calc(76 / 4000 * 100vw);
        background-color: #d6d6d6;
        border-radius: 50rem;
        padding: calc(48 / 2250 * 100vh) calc(96 / 4000 * 100vw);
        &:not(:last-child) {
          margin-right: calc(48 / 4000 * 100vw);
        }
      }
    }
    &-not-desktop {
      display: none;
      @include iPad() {
        display: flex;
        flex-direction: column;
        margin-bottom: calc(86 / 1563 * 100vw);
      }
      &-item {
        display: flex;
        justify-content: center;
        &:not(:last-child) {
          margin-bottom: calc(46 / 2779 * 100vh);
        }
        &-name {
          font-size: .875rem;
          border-radius: 50rem;
          padding: calc(30 / 2779 * 100vh) calc(64 / 1562 * 100vw);
          background-color: #d6d6d6;
          &:not(:last-child) {
            margin-right: calc(39 / 1563 * 100vw);
          }
        }
      }
    }
  }
  &-info {
    display: flex;
    justify-content: center;
    img {
      width: calc(1808 / 4000 * 100vw);
      height: calc(419 / 4000 * 100vw);
      vertical-align: bottom;
      @include iPad() {
        width: calc(1301 / 1563 * 100vw);
        height: calc(302 / 1563 * 100vw);
        margin-bottom: calc(58 / 1563 * 100vw);
      }
    }
  }
  &-sideline {
    width: calc(2540 / 4000 * 100vw);
    height: calc(10 / 4000 * 100vw);
    background-color: #e8e8e8;
    margin-top: -5px;
    margin-right: calc(730 / 4000 * 100vw);
    margin-bottom: calc(124 / 4000 * 100vw);
    margin-left: calc(730 / 4000 * 100vw);
    @include iPad() {
      display: none;
    }
  }
  &-price {
    padding-top: calc(80 / 4000 * 100vw);
    @include iPad() {
      display: none;
    }
    &-need-cost {
      display: flex;
      margin-bottom: calc(88 / 4000 * 100vw);
      margin-left: calc(620 / 4000 * 100vw);
      &-image {
        width: calc(211 / 4000 * 100vw);
        height: calc(211 / 4000 * 100vw);
        margin-left: calc(100 / 4000 * 100vw);
        margin-right: calc(100 / 4000 * 100vw);
        vertical-align: middle;
      }
      &-item {
        width: calc(660 / 4000 * 100vw);
        height: calc(660 / 4000 * 100vw);
        margin-right: calc(92 / 4000 * 100vw);
        a {
          display: inline-block;
        }
      }
      li {
        &:nth-child(1) {
          display: flex;
          align-items: center;
        }
      }
    }
    &-free {
      display: flex;
      // margin-bottom: calc(96 / 2250 * 100vh);
      margin-left: calc(620 / 4000 * 100vw);
      &-image {
        width: calc(211 / 4000 * 100vw);
        height: calc(211 / 4000 * 100vw);
        margin-left: calc(100 / 4000 * 100vw);
        margin-right: calc(100 / 4000 * 100vw);
        vertical-align: middle;
      }
      &-item {
        width: calc(660 / 4000 * 100vw);
        height: calc(660 / 4000 * 100vw);
        margin-right: calc(92 / 4000 * 100vw);
      }
      li {
        &:nth-child(1) {
          display: flex;
          align-items: center;
        }
      }
    }
    &-not-desktop {
      display: none;
      @include iPad() {
        display: flex;
        flex-direction: column;
      }
      &-need-cost {
        display: flex;
        justify-content: center;
        margin-bottom: calc(47 / 1563 * 100vw);
        &-title {
          display: flex;
          justify-content: center;
          margin-bottom: calc(40 / 1563 * 100vw);
        }
        &-image {
          width: calc(148 / 1563 * 100vw);
          height: calc(148 / 1563 * 100vw);
        }
        &-item {
          width: calc(411 / 1563 * 100vw);
          &:not(:last-child) {
            margin-right: calc(55 / 1563 * 100vw);
          }
        }
      }
      &-free{
        display: flex;
        justify-content: center;
        margin-bottom: calc(48 / 1563 * 100vw);
        &-title {
          display: flex;
          justify-content: center;
          margin-bottom: calc(60 / 1563 * 100vw);
        }
        &-image {
          width: calc(148 / 1563 * 100vw);
          height: calc(148 / 1563 * 100vw);
        }
        &-item {
          width: calc(411 / 1563 * 100vw);
          &:not(:last-child) {
            margin-right: calc(55 / 1563 * 100vw);
          }
        }
      }
    }
  }
}

</style>
