<template>
  <div id="about-us" class="about-us">
    <section class="about-us-content">
      <div class="about-us-content-vedio">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/K2kZDQQnVWA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="about-us-content-intro">
        <h2 class="about-us-content-intro-title">
          <span class="about-us-content-intro-title-en">ABOUT US</span>
          <span class="about-us-content-intro-title-tw">關於五路</span>
        </h2>
        <div class="about-us-content-intro-description">
          <p>本公司創立於民國96年，一路以來秉持著以服務為本的精神，為了讓客戶在搬家的流程更省事省心，提供了一條龍的服務，從免費估價-日夜到府-搬家服務-二手傢俱回收-廢棄物清運，一通電話幫您解決所有問題。</p>
          <p>以積極、誠懇、信用、細心，四個核心理念，服務近15年，不管是小搬家還是政府大工程，都用一樣的態度用心經營，不僅獲得客戶的信任也獲得了政府機關的親睞。</p>
        </div>
      </div>
      <div class="about-us-content-intro-not-desktop">
        <h2 class="about-us-content-intro-not-desktop-title">
          <span class="about-us-content-intro-not-desktop-title-en">ABOUT US</span>
          <span class="about-us-content-intro-not-desktop-title-tw">關於五路</span>
        </h2>
        <div class="about-us-content-intro-not-desktop-description">
          <p>本公司創立於民國96年，一路以來秉持著以服務為本的精神，為了讓客戶在搬家的流程更省事省心，提供了一條龍的服務，從免費估價-日夜到府-搬家服務-二手傢俱回收-廢棄物清運，一通電話幫您解決所有問題。</p>
          <p>以積極、誠懇、信用、細心，四個核心理念，服務近15年，不管是小搬家還是政府大工程，都用一樣的態度用心經營，不僅獲得客戶的信任也獲得了政府機關的親睞。</p>
        </div>
      </div>
    </section>
    <div class="about-us-sideline"></div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.about-us {
  // width: 100vw;
  // height: 100vh;
  @include iPad() {
    height: auto;
    // margin-bottom: calc(150 / 2779 * 100vh);
  }
  &-content {
    display: flex;
    padding-top: calc(368 / 2250 * 100vh);
    padding-left: calc(290 / 4000 * 100vw);
    margin-bottom: calc(208 / 2250 * 100vh);
    @include iPad() {
      flex-direction: column-reverse;
      padding-left: 0;
      align-items: center;
      padding-top: calc(93 / 1562 * 100vw);
      margin-bottom: calc(100 / 1562 * 100vw);
    }
    &-vedio {
      background-color: #e8e8e8;
      width: calc(1896 / 4000 * 100vw);
      height: calc(1158 / 4000 * 100vw);
      margin-right: calc(128 / 4000 * 100vw);
      @include iPad() {
        // width: calc(1080 / 1562 * 100vw);
        width: 80vw;
        height: calc(723 / 1562 * 100vw);
        margin-right: 0;
      }
    }
    &-intro {
      width: calc(1140 / 4000 * 100vw);
      // padding-top: calc(72 / 2250 * 100vh);
      // padding: calc(95 / 2250 * 100vh);
      @include iPad() {
        display: none;
        // display: flex;
        // justify-content: center;
        // align-items: flex-start;
        // width: calc(1390 / 1562  * 100vw);
        // padding-right: 0;
        // padding-left: 0;
      }
      &-title {
        display: flex;
        flex-direction: column;
        border-left: calc(26 / 4000 * 100vw) solid #3a5197;
        padding-left: calc(64 / 4000 * 100vw);
        margin-bottom: calc(93 / 4000 * 100vw);
        @include iPad() {
          display: none;
        }
        &-en {
          font-size: calc(58 / 4000 * 100vw);
          font-weight: 900;
          line-height: 2;
        }
        &-tw {
        font-size: calc(118 / 4000 * 100vw);
        font-weight: bold;
        }
      }
      &-description {
        font-size: calc(48 / 4000 * 100vw);
        line-height: 1.5;
        background-color: #f3f3f3;
        padding: calc(69 / 4000 * 100vw) calc(82 / 4000 * 100vw);
        border-radius: 1.5rem;
        @include iPad() {
          font-size: .75rem;
        }
        p {
          text-indent: calc(96 / 4000 * 100vw);
          &:nth-child(1) {
            margin-bottom: calc(104 / 4000 * 100vw);
          }
        }
      }
      &-not-desktop {
        display: none;
        @include iPad() {
          display: flex;
          align-items: center;
          width: calc(1390 / 1562 * 100vw);
        }
        &-title {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-left: calc(17 / 1562 * 100vw) solid #3a5197;
          padding-left: calc(46 / 1562 * 100vw);
          &-en {
            font-size: calc(72 / 1562 * 100vw);
            font-weight: bold;
            margin-bottom: calc(49 / 2779 * 100vh);
          }
          &-tw {
            font-size: calc(96 / 1562 * 100vw);
            font-weight: bold;
          }
        }
        &-description {
          width: calc(700 / 1562 * 100vw);
          border-radius: 1rem;
          padding: calc(58 / 1562 * 100vw);
          margin-left: calc(69 / 1562 * 100vw);
          background-color: #f3f3f3;
          margin-bottom: calc(82 / 1562 * 100vw);
          p {
            font-size: .75rem;
            line-height: 1.5;
            text-indent: 1.5rem;
            &:first-child {
              margin-bottom: calc(72 / 1562 * 100vw);
            }
          }
        }
      }
    }
  }

  &-sideline {
    width: calc(2540 / 4000 * 100vw);
    height: calc(10 / 4000 * 100vw);
    background-color: #e8e8e8;
    margin-left: calc(730 / 4000 * 100%);
    margin-right: calc(730 / 4000 * 100%);
    @include iPad() {
      display: none;
    }
  }
}
</style>
