<template>
  <div class="">
    <footer id="footer" class="footer">
      <ul class="footer-list">
        <li class="footer-list-company-info">
          <!-- <img src="../assets/footer-company-info.png" alt="" class="footer-list-company-info-title"> -->
          <span class="footer-list-company-info-title">公司資訊</span>
          <ul class="footer-list-company-info-content">
            <li>電話 : 04-2202-0077</li>
            <li>傳真 : 048-659-926</li>
            <li>統編 : 28769360</li>
            <li>地址 : 台中市北區民權路343號</li>
            <li>信箱 : aas0513@yahoo.com</li>
          </ul>
        </li>
        <li class="footer-list-sideline"></li>
        <li class="footer-list-about-five-way">
          <span class="footer-list-about-five-way-title">關於五路</span>
          <ul class="footer-list-about-five-way-content">
            <li>
              <router-link to="/#service-items">服務項目</router-link>
            </li>
            <li>
              <router-link to="/#move-house-tidbits">搬家花絮</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CommonProblem' }">常見問題</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PriceList' }">價目表</router-link>
            </li>
          </ul>
        </li>
        <li class="footer-list-sideline"></li>
        <li class="footer-list-follow-us">
          <span class="footer-list-follow-us-title">關注我們</span>
          <ul class="footer-list-follow-us-content">
            <li class="">
              <a href="https://reurl.cc/8ydgLM" class="footer-list-follow-us-content-facebook" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/wu_lu_move_house/" class="footer-list-follow-us-content-instagram" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCmQMJHVJY-rzOTTs1u157XA" class="footer-list-follow-us-content-youtube" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li>
              <a href="https://reurl.cc/KxNavm" class="footer-list-follow-us-content-line" target="_blank" rel="noopener noreferrer"></a>
            </li>
          </ul>
          <div class="footer-list-follow-us-qr-code"></div>
        </li>
      </ul>
    </footer>
    <footer id="footer-not-desktop" class="footer-not-desktop">
      <div class="footer-not-desktop-follow-us">
        <h3 class="footer-not-desktop-follow-us-title"><span>關注我們</span></h3>
        <div class="footer-not-desktop-follow-us-block">
          <ul class="footer-not-desktop-follow-us-block-list">
            <li class="footer-not-desktop-follow-us-block-list-item">
              <a class="footer-not-desktop-follow-us-block-list-item-facebook" href="https://reurl.cc/8ydgLM" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="footer-not-desktop-follow-us-block-list-item">
              <a class="footer-not-desktop-follow-us-block-list-item-instagram" href="https://www.instagram.com/wu_lu_move_house/" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="footer-not-desktop-follow-us-block-list-item">
              <a class="footer-not-desktop-follow-us-block-list-item-youtube" href="https://www.youtube.com/channel/UCmQMJHVJY-rzOTTs1u157XA" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="footer-not-desktop-follow-us-block-list-item">
              <a class="footer-not-desktop-follow-us-block-list-item-line" href="https://reurl.cc/KxNavm" target="_blank" rel="noopener noreferrer"></a>
            </li>
          </ul>
          <div class="footer-not-desktop-follow-us-block-qr-code"></div>
        </div>
      </div>
      <div class="footer-not-desktop-block">
        <div class="footer-not-desktop-block-company-info">
          <h3 class="footer-not-desktop-block-company-info-title"><span>公司資訊</span></h3>
          <ul class="footer-not-desktop-block-company-info-list">
            <li class="footer-not-desktop-block-company-info-list-item">電話 : 04-2202-0077</li>
            <li class="footer-not-desktop-block-company-info-list-item">傳真 : 048-659-926</li>
            <li class="footer-not-desktop-block-company-info-list-item">統編 : 28769360</li>
            <li class="footer-not-desktop-block-company-info-list-item">地址 : 台中市北區民權路343號</li>
            <li class="footer-not-desktop-block-company-info-list-item">信箱 : aas0513@yahoo.com</li>
          </ul>
        </div>
        <div class="footer-not-desktop-block-about-five-way">
          <h3 class="footer-not-desktop-block-about-five-way-title"><span>關於我們</span></h3>
          <ul class="footer-not-desktop-block-about-five-way-list">
            <li class="footer-not-desktop-block-about-five-way-list-item">
              <router-link to="/#service-items">服務項目</router-link>
            </li>
            <li class="footer-not-desktop-block-about-five-way-list-item">
              <router-link to="/#move-house-tidbits">搬家花絮</router-link>
            </li>
            <li class="footer-not-desktop-block-about-five-way-list-item">
              <router-link :to="{ name: 'CommonProblem' }">常見問題</router-link>
            </li>
            <li class="footer-not-desktop-block-about-five-way-list-item">
              <router-link :to="{ name: 'PriceList' }">價目表</router-link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.footer {
  display: flex;
  background-color: rgba(23, 42, 129, .9);
  padding-top: calc(51 / 1672 * 100vw);
  padding-bottom: calc(61 / 1672 * 100vw);
  padding-left: calc(178 / 4000 * 100vw);
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: 400;
  @include iPad() {
    display: none;
  }
  &-list {
    display: flex;
    @include iPad() {
      flex-direction: column;
    }
    &-company-info {
      margin-right: calc(114 / 4000 * 100vw);
      @include iPad() {
        margin-right: 0;
        margin-bottom: calc(96 / 2779 * 100vh);
        width: calc(1400 / 1562  * 100vw);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-title {
        display: inline-block;
        border-radius: 50rem;
        border: 2px solid #ffffff;
        font-size: calc(64 / 4000 * 100vw);
        font-family: 'Noto Sans TC', sans-serif;
        font-weight: 400;
        color: #ffffff;
        padding-top: calc(34 / 4000 * 100vw);
        padding-right: calc(78 / 4000 * 100vw);
        padding-bottom: calc(34 / 4000 * 100vw);
        padding-left: calc(88 / 4000 * 100vw);
        margin-bottom: calc(130 / 4000 * 100vw);
        @include iPad() {
          font-size: 1rem;
        }
      }
      &-content {
        color: #ffffff;
        font-size: calc(52 / 4000 * 100vw);
        font-family: 'Noto Sans TC', sans-serif;
        font-weight: 400;
        letter-spacing: .2rem;
        text-indent: -.2rem;
        direction:rtl;
        padding-left: calc(40 / 4000 * 100vw);
        li {
          margin-bottom: calc(40 / 4000 * 100vw);
        }
      }
    }
    &-about-five-way {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(638 / 4000 * 100vw);
      @include iPad() {
        width: calc(1400 / 1562  * 100vw);
        align-items: center;
        margin-bottom: calc(96 / 2779 * 100vh);
      }
      &-title {
        display: inline-block;
        border-radius: 50rem;
        border: 2px solid #ffffff;
        font-size: calc(64 / 4000 * 100vw);
        font-family: 'Noto Sans TC', sans-serif;
        font-weight: 400;
        color: #ffffff;
        padding-top: calc(34 / 4000 * 100vw);
        padding-right: calc(78 / 4000 * 100vw);
        padding-bottom: calc(34 / 4000 * 100vw);
        padding-left: calc(88 / 4000 * 100vw);
        margin-bottom: calc(106 / 4000 * 100vw);
        @include iPad() {
          font-size: 1rem;
        }
      }
      &-content {
        font-size: calc(52 / 4000 * 100vw);
        font-family: 'Noto Sans TC', sans-serif;
        font-weight: 400;
        letter-spacing: .2rem;
        text-indent: -.2rem;
        direction:rtl;
        // padding-left: calc(40 / 4000 * 100vw);
        li {
          margin-bottom: calc(40 / 4000 * 100vw);
          a {
            color: #ffffff;
            text-decoration: none;
            font-family: 'Noto Sans TC', sans-serif;
            font-weight: 400;
          }
        }
      }
    }
    &-follow-us {
      margin-left: calc(128 / 4000 * 100vw);
      @include iPad() {
        width: calc(1400 / 1562 * 100vw);
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-title {
        display: inline-block;
        border-radius: 50rem;
        border: 2px solid #ffffff;
        font-size: calc(64 / 4000 * 100vw);
        font-family: 'Noto Sans TC', sans-serif;
        font-weight: 400;
        color: #ffffff;
        padding-top: calc(34 / 4000 * 100vw);
        padding-right: calc(78 / 4000 * 100vw);
        padding-bottom: calc(34 / 4000 * 100vw);
        padding-left: calc(88 / 4000 * 100vw);
        margin-bottom: calc(106 / 4000 * 100vw);
        @include iPad() {
          font-size: 1rem;
          font-family: 'Noto Sans TC', sans-serif;
          font-weight: 400;
        }
      }
      &-content {
        display: flex;
        margin-bottom: calc(98 / 4000 * 100vw);
        &-facebook {
          display: inline-block;
          width: calc(322 / 4000 * 100vw);
          height: calc(322 / 4000 * 100vw);
          background-image: url(../assets/fb_icon.png);
          background-size: cover;
          background-repeat: no-repeat;
          @include iPad() {
            width: calc(322 / 1562  * 100vw);
            height: calc(322 / 1562  * 100vw);
          }
        }
        &-instagram {
          display: inline-block;
          width: calc(322 / 4000 * 100vw);
          height: calc(322 / 4000 * 100vw);
          background-image: url(../assets/ig_icon.png);
          background-size: cover;
          background-repeat: no-repeat;
          @include iPad() {
            width: calc(322 / 1562  * 100vw);
            height: calc(322 / 1562  * 100vw);
          }
        }
        &-youtube {
          display: inline-block;
          width: calc(322 / 4000 * 100vw);
          height: calc(322 / 4000 * 100vw);
          background-image: url(../assets/youtube_icon.png);
          background-size: cover;
          background-repeat: no-repeat;
          @include iPad() {
            width: calc(322 / 1562  * 100vw);
            height: calc(322 / 1562  * 100vw);
          }
        }
        &-line {
          display: inline-block;
          width: calc(322 / 4000 * 100vw);
          height: calc(322 / 4000 * 100vw);
          background-image: url(../assets/line_icon.png);
          background-size: cover;
          background-repeat: no-repeat;
          @include iPad() {
            width: calc(322 / 1562  * 100vw);
            height: calc(322 / 1562  * 100vw);
          }
        }
      }
      &-qr-code {
        width: calc(994 / 4000 * 100vw);
        height: calc(434 / 4000 * 100vw);
        margin-left: calc(132  / 4000 * 100vw);
        background-image: url(../assets/follow-us-qr-code.png);
        background-size: cover;
        background-repeat: no-repeat;
        @include iPad() {
          width: calc(960 / 1562  * 100vw);
          height: calc(434 / 1562 * 100vw);
          margin-left: 0;
        }
      }
    }
    &-sideline {
      flex-shrink: 0;
      width: calc(10 / 4000 * 100vw);
      height: calc(452 / 4000 * 100vw);
      background-color: rgba(255, 255, 255, .25);
      margin-top: calc(368 / 4000 * 100vw);
      margin-bottom: calc(305 / 4000 * 100vw);
      @include iPad() {
        display: none;
      }
    }
  }
  &-not-desktop {
    display: none;
    @include iPad() {
      display: block;
      background-color: rgba(23, 42, 129, .9);
      padding-top: calc(89 / 1563 * 100vw);
      padding-bottom: calc(115 / 1563 * 100vw);
    }
    &-follow-us {
      color: #ffffff;
      &-title {
        display: flex;
        justify-content: center;
        margin-bottom: calc(79 / 1563 * 100vw);
        span {
          display: inline-block;
          font-size: calc(44 / 1563 * 100vw);
          font-family: 'Noto Sans TC', sans-serif;
          font-weight: 400;
          letter-spacing: .1rem;
          text-indent: -.1rem;
          direction:rtl;
          border: calc(10 / 1563 * 100vw) solid #ffffff;
          border-radius: 50rem;
          padding: calc(23 / 1563 * 100vw) calc(62 / 1563 * 100vw);
        }
      }
      &-block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(117 / 1563 * 100vw);
        &-list {
          display: flex;
          margin-right: calc(60 / 1563 * 100vw);
          &-item {
            &:not(:last-child) {
              margin-right: calc(47 / 1563 * 100vw);
            }
            a {
              display: inline-block;
              width: calc(165 / 1563 * 100vw);
              height: calc(219 / 1563 * 100vw);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &-facebook {
              background-image: url(../assets/fb_icon.png);
            }
            &-instagram {
              background-image: url(../assets/ig_icon.png);
            }
            &-youtube {
              background-image: url(../assets/youtube_icon.png);
            }
            &-line {
              background-image: url(../assets/line_icon.png);
            }
          }
        }
        &-qr-code {
          width: calc(569 / 1563 * 100vw);
          height: calc(248 / 1563 * 100vw);
          background-image: url(../assets/follow-us-qr-code.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
    &-block {
      display: flex;
      justify-content: center;
      &-company-info {
        color: #ffffff;
        &-title {
          display: flex;
          margin-bottom: calc(88 / 1563 * 100vw);
          span {
            display: inline-block;
            font-size: calc(44 / 1563 * 100vw);
            font-family: 'Noto Sans TC', sans-serif;
            font-weight: 400;
            letter-spacing: .1rem;
            text-indent: -.1rem;
            direction:rtl;
            border: calc(10 / 1563 * 100vw) solid #ffffff;
            border-radius: 50rem;
            padding: calc(23 / 1563 * 100vw) calc(60 / 1563 * 100vw);
          }
        }
        &-list {
          border-right: calc(6 / 1563 * 100vw) solid rgba(255, 255, 255, .25);
          padding-right: calc(72 / 1536 * 100vw);
          &-item {
            font-size: calc(36 / 1563 * 100vw);
            font-family: 'Noto Sans TC', sans-serif;
            font-weight: 400;
            &:not(:last-child) {
              line-height: 1.8;
            }
          }
        }
      }
      &-about-five-way {
        color: #ffffff;
        &-title {
          display: flex;
          justify-content: center;
          margin-bottom: calc(70 / 1563 * 100vw);
          margin-left: calc(64 / 1563 * 100vw);
          span {
            display: inline-block;
            font-size: calc(44 / 1563 * 100vw);
            font-family: 'Noto Sans TC', sans-serif;
            font-weight: 400;
            letter-spacing: .1rem;
            text-indent: -.1rem;
            direction:rtl;
            border: calc(10 / 1563 * 100vw) solid #ffffff;
            border-radius: 50rem;
            padding: calc(23 / 1563 * 100vw) calc(60 / 1563 * 100vw);
          }
        }
        &-list {
          padding-left: calc(134 / 1563 * 100vw);
          &-item {
            a {
              font-size: calc(36 / 1563 * 100vw);
              font-family: 'Noto Sans TC', sans-serif;
              font-weight: 400;
              line-height: 1.8;
              text-decoration: none;
              letter-spacing: .1rem;
              text-indent: -.1rem;
              direction:rtl;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

</style>
