<template>
  <div>
    <h2 class="move-house-flow-title"><span>搬家流程</span></h2>
    <div class="move-house-flow">
      <ul class="move-house-flow-list">
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-step1.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-row-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-step2.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-row-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-step3.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-row-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list-item">
          <img src="../assets/move-house-step4.png" alt="" />
        </li>
      </ul>
      <ul class="move-house-flow-list2">
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-step5.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-row-reverse-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-step6.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-row-reverse-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-step7.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-row-reverse-arrow-icon.png" alt="" />
        </li>
        <li class="move-house-flow-list2-item">
          <img src="../assets/move-house-step8.png" alt="" />
        </li>
      </ul>
      <ul class="move-house-flow-not-desktop-list">
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step1.png" alt="">
        </li>
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step2.png" alt="">
        </li>
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step3.png" alt="">
        </li>
      </ul>
      <ul class="move-house-flow-not-desktop-list2">
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step4.png" alt="">
        </li>
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step5.png" alt="">
        </li>
        <li class="move-house-flow-not-desktop-list-item">
          <img src="../assets/move-house-step6.png" alt="">
        </li>
      </ul>
      <div class="move-house-flow-not-desktop-setp7">
        <img src="../assets/move-house-step7.png" alt="">
      </div>
      <div class="move-house-flow-not-desktop-arrow">
        <img src="../assets/move-house-column-arrow-icon.png" alt="">
      </div>
      <div class="move-house-flow-not-desktop-setp8">
        <img src="../assets/move-house-step8.png" alt="">
      </div>
    </div>
    <div class="sideline"></div>
    <div id="move-house-tidbits" class="move-house-tidbits">
      <h2 class="move-house-tidbits-title"><span>搬家花絮</span></h2>
      <ul class="move-house-tidbits-list">
        <!-- <li class="move-house-tidbits-list-item">
          <img src="../assets/move-house-tidbits-1.jpg" alt="">
          <img src="../assets/move-house-tidbits-2.jpg" alt="">
          <img src="../assets/move-house-tidbits-3.jpg" alt="">
        </li> -->
        <li class="move-house-tidbits-list-item">
          <swiper
            :slides-per-view="3"
            :space-between="16"
            :autoplay ="{ delay: 3500 }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }"></router-link>
              <img src="../assets/move-house-tidbits-1.jpg" alt=""/>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-2.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-3.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-7.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-8.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-9.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-10.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-11.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-12.jpg" alt=""/>
              </router-link>
            </swiper-slide>
          </swiper>
        </li>
        <li class="move-house-tidbits-list-item">
          <swiper
            :slides-per-view="3"
            :space-between="16"
            :autoplay ="{ delay: 3500 }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-4.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-5.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-6.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-13.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-14.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-15.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-16.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-17.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-18.jpg" alt=""/>
              </router-link>
            </swiper-slide>
          </swiper>
        </li>
      </ul>
      <ul class="move-house-tidbits-list-not-desktop">
        <li class="move-house-tidbits-list-not-desktop-item">
          <swiper
            :slides-per-view="1.7"
            :space-between="16"
            :autoplay ="{ delay: 3500 }"
            navigation
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-4.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-5.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-6.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-13.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-14.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-15.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-16.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-17.jpg" alt=""/>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'MoveHouseTidbits' }">
                <img src="../assets/move-house-tidbits-18.jpg" alt=""/>
              </router-link>
            </swiper-slide>
          </swiper>
        </li>
      </ul>
      <div class="move-house-tidbits-more">
        <router-link :to="{ name: 'MoveHouseTidbits' }" class="move-house-tidbits-more-link"
          ><img src="../assets/more.png" alt=""></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
import '@/assets/scss/navigation.scss'
import '@/assets/scss/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default {
  name: 'MoveHouseFlowAndTidbits',
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    var onSwiper = swiper => {
      // console.log(swiper)
    }

    var onSlideChange = () => {
      // console.log('slide change')
    }
    return { onSwiper, onSlideChange }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

.move-house-flow {
  // padding-right: calc(720 / 4000 * 100vw);
  padding-bottom: calc(111 / 4000 * 100vw);
  // padding-left: calc(720 / 4000 * 100vw);
  @include iPad() {
    padding-right: calc(137 / 1563 * 100vw);
    padding-left: calc(137 / 1563 * 100vw);
    padding-bottom: 0;
    margin-bottom: calc(100 / 1563 * 100vw);
  }
  &-title {
    display: flex;
    justify-content: center;
    padding-top: calc(250 / 4000 * 100vw);
    margin-bottom: calc(99 / 4000 * 100vw);
    @include iPad() {
      padding-top: 0;
      margin-bottom: calc(67 / 1563 * 100vw);
    }
    span {
      font-size: calc(84 / 4000 * 100vw);
      color: #ffffff;
      text-align: center;
      // font-weight: bold;
      letter-spacing: .2rem;
      background-color: rgba(23, 42, 129, .9);
      border-radius: 50rem;
      padding-top: calc(42 / 4000 * 100vw);
      padding-right: calc(84 / 4000 * 100vw);
      padding-bottom: calc(42 / 4000 * 100vw);
      padding-left: calc(96 / 4000 * 100vw);
      // width: calc(720 / 4000 * 100vw);
      // height: calc(213 / 2250 * 100vh);
      @include iPad() {
        font-size: 1rem;
        padding: calc(34 / 2779 * 100vh) calc(84 / 1563 * 100vw);
        margin-bottom: 0;
      }
    }
  }
  &-list {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: calc(82 / 4000 * 100vw);
    justify-content: center;
    padding-left: calc(458 / 4000 * 100vw);
    padding-right: calc(724 / 4000 * 100vw);
    @include iPad() {
      display: none;
    }
    &-item {
      &:nth-child(odd) {
        // width: calc(473 / 4000 * 100vw);
        // flex: 4;
        display: flex;
        align-items: center;
        align-self: flex-end;
        margin-bottom: calc(14 / 4000 * 100vw);
      }
      &:nth-child(even) {
        // flex: 1;
        display: flex;
        align-items: center;
        // margin-right: calc(100 / 4000 * 100vw);
        // margin-left: calc(100 / 4000 * 100vw);
        img {
          min-width: calc(146 / 4000 * 100vw);
          min-height: calc(146 / 4000 * 100vw);
          width: calc(146 / 4000 * 100vw);
          height: calc(146 / 4000 * 100vw);
        }
      }
      &:first-child {
        margin-right: calc(101 / 4000 * 100vw );
        img {
          min-width: calc(423 / 4000 * 100vw);
          min-height: calc(483 / 4000 * 100vw);
          width: calc(423 / 4000 * 100vw);
          height: calc(483 / 4000 * 100vw);
        }
      }
      &:nth-child(3) {
        margin-left: calc(104 / 4000 * 100vw );
        margin-right: calc(93 / 4000 * 100vw);
        img {
          min-width: calc(427 / 4000 * 100vw);
          min-height: calc(435 / 4000 * 100vw);
          width: calc(427 / 4000 * 100vw);
          height: calc(435 / 4000 * 100vw);
        }
      }
      &:nth-child(5) {
        margin-left: calc(105 / 4000 * 100vw);
        margin-right: calc(76 / 4000 * 100vw);
        img {
          min-width: calc(423 / 4000 * 100vw);
          min-height: calc(487  / 4000 * 100vw);
          width: calc(423 / 4000 * 100vw);
          height: calc(487  / 4000 * 100vw);
        }
      }
      &:last-child {
        position: relative;
        margin-left: calc(114 / 4000 * 100vw);
        margin-bottom: 0;
        img {
          min-width: calc(459 / 4000 * 100vw);
          min-height: calc(529 / 4000 * 100vw);
          width: calc(459 / 4000 * 100vw);
          height: calc(529 / 4000 * 100vw);
        }
        &::after {
          content: '';
          position: absolute;
          left: 35%;
          bottom: -37.5%;
          width: calc(146 / 4000 * 100vw);
          height: calc(146 / 4000 * 100vw);
          background-image: url(../assets/move-house-column-arrow-icon.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  &-list2 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    justify-content: center;
    // align-items: flex-end;
    padding-left: calc(472 / 4000 * 100vw);
    padding-right: calc(680  / 4000 * 100vw);
    @include iPad() {
      display: none;
    }
    &-item {
      &:nth-child(odd) {
        // width: calc(473 / 4000 * 100vw);
        // flex: 4;
        display: flex;
        // align-items: center;
        align-self: flex-end;
        margin-bottom: calc(30 / 4000 * 100vw);
      }
      &:nth-child(even) {
        // flex: 1;
        display: flex;
        align-items: center;
        // margin-right: calc(100 / 4000 * 100vw);
        // margin-left: calc(100 / 4000 * 100vw);
        img {
          min-width: calc(146 / 4000 * 100vw);
          min-height: calc(146 / 4000 * 100vw);
          width: calc(146 / 4000 * 100vw);
          height: calc(146 / 4000 * 100vw);
        }
      }
      &:first-child {
        margin-left: calc(92 / 4000 * 100vw);
        img {
          min-width: calc(535 / 4000 * 100vw);
          min-height: calc(458 / 4000 * 100vw);
          width: calc(535 / 4000 * 100vw);
          height: calc(458 / 4000 * 100vw);
          // background-size: cover;
        }
      }
      &:nth-child(3) {
        margin-right: calc(66 / 4000 * 100vw);
        margin-left: calc(90 / 4000 * 100vw);
        img {
          min-width: calc(447 / 4000 * 100vw);
          min-height: calc(458 / 4000 * 100vw);
          width: calc(447 / 4000 * 100vw);
          height: calc(458 / 4000 * 100vw);
        }
      }
      &:nth-child(5) {
        margin-right: calc(56 / 4000 * 100vw);
        margin-left: calc(70 / 4000 * 100vw);
        img {
          min-width: calc(500 / 4000 * 100vw);
          min-height: calc(505 / 4000 * 100vw);
          width: calc(500 / 4000 * 100vw);
          height: calc(505 / 4000 * 100vw);
        }
      }
      &:last-child {
        margin-right: calc(99 / 4000 * 100vw);
        margin-bottom: 0;
        img {
          min-width: calc(424 / 4000 * 100vw);
          min-height: calc(666 / 4000 * 100vw);
          width: calc(424 / 4000 * 100vw);
          height: calc(666 / 4000 * 100vw);
        }
      }
    }
  }
  &-not-desktop {
    &-list {
      display: none;
      @include iPad() {
        display: flex;
        align-items: flex-end;
        margin-bottom: calc(72 / 2779 * 100vh);
      }
      &-item {
        @include iPad() {
          &:nth-child(1) {
            margin-right: calc(136 / 1563 * 100vw);
          }
          &:nth-child(2) {
            margin-right: calc(136 / 1563 * 100vw);
          }
        }
      }
    }
    &-list2 {
      display: none;
      @include iPad() {
        display: flex;
        align-items: flex-end;
        margin-bottom: calc(88 / 2779 * 100vh);
      }
      &-item {
        &:nth-child(1) {
          margin-right: calc(87 / 1563 * 100vw);
        }
        &:nth-child(2) {
          margin-right: calc(79 / 1563 * 100vw);
        }
      }
    }
  }
}

.move-house-flow-not-desktop-setp7 {
  display: none;
  @include iPad() {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: calc(46 / 2779 * 100vh);
    img {
      width: calc(398 / 1563 * 100vw);
      height: calc(402 / 1563 * 100vw);
    }
  }
}

.move-house-flow-not-desktop-arrow {
  display: none;
  @include iPad() {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: calc(56 / 2779 * 100vh);
    img {
      width: calc(137 / 1563 * 100vw);
      height: calc(137 / 1563 * 100vw);
    }
  }
}

.move-house-flow-not-desktop-setp8 {
  display: none;
  @include iPad() {
    display: flex;
    justify-content: center;
    img {
      width: calc(398 / 1563 * 100vw);
      height: calc(531 / 1563 * 100vw);
    }
  }
}

.sideline {
  width: calc(2540 / 4000 * 100vw);
  height: calc(10 / 4000 * 100vw);
  background-color: #e8e8e8;
  // margin-top: calc(130 / 2250 * 100vh);
  margin-right: calc(730 / 4000 * 100vw);
  // margin-bottom: calc(106 / 2250 * 100vh);
  margin-left: calc(730 / 4000 * 100vw);
  @include iPad() {
    display: none;
  }
}

.move-house-tidbits {
  padding-top: calc(225 / 4000 * 100vw);
  @include iPad() {
    // padding-top: calc(120 / 1563 * 100vw);
    padding-top: 0;
  }
  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: calc(99 / 4000 * 100vw);
    @include iPad() {
      margin-bottom: calc(70 / 1563 * 100vw);
    }
    span {
      font-size: calc(84 / 4000 * 100vw);
      color: #ffffff;
      text-align: center;
      // font-weight: bold;
      letter-spacing: .2rem;
      background-color: rgba(23, 42, 129, .9);
      border-radius: 50rem;
      padding-top: calc(42 / 4000 * 100vw);
      padding-right: calc(84 / 4000 * 100vw);
      padding-bottom: calc(42 / 4000 * 100vw);
      padding-left: calc(96 / 4000 * 100vw);
      // width: calc(720 / 4000 * 100vw);
      // height: calc(213 / 2250 * 100vh);
      @include iPad() {
        font-size: 1rem;
        padding: calc(34 / 1563 * 100vw) calc(81  / 1563 * 100vw);
        // margin-bottom: calc(57 / 2279 * 100vh);
      }
    }
  }
  &-list {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    padding-right: calc(720 / 4000 * 100vw);
    padding-left: calc(720 / 4000 * 100vw);
    @include iPad() {
      display: none;
    }
    &-not-desktop {
      display: none;
      @include iPad() {
        display: flex;
        flex-direction: column;
        margin-bottom: calc(55 / 1563 * 100vw);
      }
      &-item {
        display: none;
        @include iPad() {
          display: flex;
          justify-content: center;
          border-radius: 0.5rem;
          padding: calc(34 / 1563 * 100vw) calc(32 / 1563 * 100vw);
          background-color: #e8e8e8;
        }
        img {
          width: calc(887 / 1563 * 100vw);
        }
      }
    }
    &-item {
      display: flex;
      justify-content: center;
      border-radius: 0.5rem;
      padding-top: calc(44 / 4000 * 100vw);
      padding-right: calc(47 / 4000 * 100vw);
      padding-bottom: calc(44 / 4000 * 100vw);
      padding-left: calc(47 / 4000 * 100vw);
      background-color: #e8e8e8;
      &:nth-child(1) {
        margin-bottom: calc(55 / 4000 * 100vw);
      }
      img {
        // background-color: #e8e8e8;
        width: calc(1197 / 4000 * 100vw);
        // padding-right: calc(35 / 4000 * 100vw);
        // padding-left: calc(35 / 4000 * 100vw);
      }
    }
  }
  &-more {
    display: flex;
    flex-direction: row-reverse;
    justify-self: flex-end;
    // padding-right: calc(720 / 4000 * 100vw);
    margin-top: calc(40 / 4000 * 100vw);
    margin-right: calc(720 / 4000 * 100vw);
    margin-bottom: calc(40 / 4000 * 100vw);
    @include iPad() {
      padding-right: 0;
      margin-right: calc(140 / 1562 * 100vw);
      margin-bottom: calc(76 / 1562 * 100vw);
    }
    &-link {
      display: inline-block;
      border-radius: 50rem;
      padding: calc(27 / 4000 * 100vw) 0;
      text-decoration: none;
      font-size: calc(84 / 4000 * 100vw);
      letter-spacing: 0.2rem;
      color: #ffffff;
      // background-color: #162a81;
      img {
        width: calc(360 / 4000 * 100vw);
        height: calc(135 / 4000 * 100vw);
        @include iPad() {
          width: calc(320 / 1563  * 100vw);
          height: calc(120 / 1563 * 100vw);
        }
      }
      @include iPad() {
        padding-left: calc(68 / 1563 * 100vw);
        // padding-right: calc(68 / 1563 * 100vw);
      }
    }
  }
}
</style>
