<template>
  <div class="">
    <header>
      <h1 class="logo">
        <router-link to="/" class="logo-image-link">搬家評價優良、值得信賴、價格公道、收費合理、口碑推薦</router-link>
        <router-link to="/" class="logo-text-link">五路環保科技有限公司</router-link>
      </h1>
      <nav class="header-nav">
        <ul class="header-nav-list">
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" to="/#about-us">關於我</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" to="/#service-items">服務項目</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" to="/#move-house-tidbits">搬家花絮</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" :to="{ name: 'CommonProblem' }">常見問題</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" :to="{ name: 'PriceList' }">價目表</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" :to="{ name: 'AppraisalList' }">搬家估價單</router-link>
          </li>
          <li class="header-nav-list-item">
            <router-link class="header-nav-list-item-link" to="/#footer">聯絡五路</router-link>
          </li>
        </ul>
      </nav>
      <nav class="header-nav-not-desktop" :class="{ open: isHamburgerMenuOpen }">
        <ul class="header-nav-not-desktop-list">
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" to="/#about-us">關於我</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" to="/#service-items">服務項目</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" to="/#move-house-tidbits">搬家花絮</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" :to="{ name: 'CommonProblem' }">常見問題</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" :to="{ name: 'PriceList' }">價目表</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" :to="{ name: 'AppraisalList' }">搬家估價單</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <router-link class="header-nav-not-desktop-list-item-link" @click.prevent="showHamburgerMenu()" to="/#footer-not-desktop">聯絡五路</router-link>
          </li>
          <li class="header-nav-not-desktop-list-item">
            <a href="#" @click.prevent="showHamburgerMenu()"><img src="../assets/chevron-up-solid.svg" alt=""></a>
          </li>
        </ul>
      </nav>
      <a class="show-hamburger-menu" href="#" @click.prevent="showHamburgerMenu()"></a>
    </header>
    <!-- <router-link to="" ><h1>五路環保科技有限公司</h1></router-link> -->
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
// import { useRouter, useRoute } from 'vue-router'

// Vue.use(useRouter, useRoute)

export default {
  name: 'Header',
  setup () {
    // const route = useRoute()
    const isHamburgerMenuOpen = ref(false)
    // const scrollFix = (hashbang) => {
    //   location.hash = hashbang
    // }

    onMounted(() => {
      // setTimeout(() => scrollFix(route.hash), 1)
    })

    const showHamburgerMenu = () => {
      // console.log('isHamburgerMenuOpen=>', isHamburgerMenuOpen)
      isHamburgerMenuOpen.value = !isHamburgerMenuOpen.value
    }

    return { isHamburgerMenuOpen, showHamburgerMenu }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/helpers/_mixin.scss';

header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  // padding:calc(52 / 4000 * 100vw) calc(69 / 4000 * 100vw);
  padding-top: calc(40 / 4000 * 100vw);
  padding-right: calc(70 / 4000 * 100vw);
  padding-bottom: calc(51 / 4000 * 100vw);
  padding-left: calc(88 / 4000 * 100vw);
  background-color: rgba(23, 42, 129, .9);
  @include iPad() {
    padding: calc(62 / 1562  * 100vw) calc(56 / 1562 * 100vw);
    align-items: center;
  }
}

.logo {
  display: inline-flex;
  align-items: center;
}

.logo-image-link {
  display: inline-block;
  width: calc(320 / 4000 * 100vw);
  height: calc(242 / 4000 * 100vw);
  background-image: url(../assets/logo.png);
  background-size: cover;
  text-indent: 101%;
  overflow:hidden;
  white-space:nowrap;
  margin-right: calc(30 / 4000 * 100vw);
  @include iPad() {
    width: calc(264 / 1562  * 100vw);
    height: calc(202 / 1562 * 100vw);
    margin-right: calc(40 / 1562 * 100vw);
  }
}

.logo-text-link {
  color: #ffffff;
  font-size: calc(44 / 1713 * 100vw);
  font-weight: bold;
  text-decoration: none;
  letter-spacing: .15rem;
  white-space:nowrap;
  @include iPad() {
    letter-spacing: .2rem;
    font-size: calc(84 / 1562  * 100vw);
  }
}

.header-nav {
  // padding-top: calc(84 / 4000 * 100vw);
  display: flex;
  align-items: center;
  @include iPad() {
    display: none;
  }
  &-list {
    display: flex;
    &-item {
      margin-left: calc(6 / 1713 * 100vw);
      &-link {
        display: inline-block;
        // width: 100%;
        // text-indent: 101%;
        overflow:hidden;
        text-decoration: none;
        white-space:nowrap;
        color: #ffffff;
        background-color: rgba(255, 255, 255, .2);
        font-size: calc(22 / 1713 * 100vw);
        padding: calc(10 / 1713 * 100vw) calc(18 / 1713 * 100vw);
        border-radius: 50rem;
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center center;
        // padding: calc(20 / 4000 * 100vw) calc(40 / 4000 * 100vw);
        // white-space:nowrap;
        // letter-spacing: (20 / 4000 * 100vw);
      }
    }
  }
}

.header-nav-not-desktop {
  display: none;
  @include iPad() {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 1s;
  }
  ul {
    width: 100%;
    background-color: rgba(23, 42, 129, .8);
    li {
      padding-right: calc(56 / 1562 * 100vw);
      padding-left: calc(56 / 1562 * 100vw);
      &:focus {
        background-color: rgba(232, 232, 232, .2);
      }
      &:hover {
        background-color: rgba(232, 232, 232, .2);
      }
      &:not(:last-child) {
        a {
          border-bottom: 1px solid rgba(255, 255, 255, .6);
        }
      }
      &:nth-child(7) {
        a {
          border-bottom: none;
        }
      }
      &:last-child {
        a {
          padding-top: calc(16 / 1562 * 100vw);
          padding-bottom: calc(16 / 1562 * 100vw);
          img {
            width: calc(58 / 1562 * 100vw);
          }
        }
      }
      a {
        display: block;
        text-decoration: none;
        text-align: center;
        color: #ffffff;
        font-size: calc(64 / 1562 * 100vw);
        padding-top: calc(60 / 1562 * 100vw);
        padding-bottom: calc(60 / 1562 * 100vw);
      }
    }
  }
}

.show-hamburger-menu {
  display: none;
  @include iPad() {
    display: inline-block;
    vertical-align: middle;
    width: calc(96 / 1562  * 100vw);
    height: calc(96 / 1562 * 100vw);
    background-image: url(../assets/hamburger-menu.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.open {
  max-height: 800px;
}

</style>
